var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import merge from 'lodash/merge';
import { useMemo } from 'react';
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
//
import { useSettingsContext } from './SettingsContext';
export default function ThemeContrast(_a) {
    var children = _a.children;
    var outerTheme = useTheme();
    var _b = useSettingsContext(), themeContrast = _b.themeContrast, themeMode = _b.themeMode;
    var isLight = themeMode === 'light';
    var isContrastBold = themeContrast === 'bold';
    var themeOptions = useMemo(function () { return ({
        palette: {
            background: __assign({}, (isContrastBold && {
                default: isLight ? outerTheme.palette.grey[100] : outerTheme.palette.grey[900],
            })),
        },
        components: {
            MuiCard: {
                styleOverrides: __assign({}, (isContrastBold && {
                    root: {
                        boxShadow: outerTheme.customShadows.z4,
                    },
                })),
            },
        },
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLight, themeContrast]);
    var theme = createTheme(merge(outerTheme, themeOptions));
    return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), children] })));
}
